<template>
  <div>
    <div
      v-if="false"
      class="mt-1"
    >
      sub variant: {{ value }}
    </div>
    <!-- add sub variant -->
    <b-button
      v-if="hasVariant && !hasSubVariant"
      variant="outline-primary"
      class="mt-2"
      :disabled="!variants.length"
      @click="enableSubVariant"
    ><feather-icon
      icon="PlusIcon"
      class="mr-50"
    />Tambah Tipe Varian</b-button>

    <!-- sub variant label -->
    <FormLabel
      v-if="hasSubVariant"
      label="Tipe Varian 2"
      class="mt-2"
    />
    <b-row
      v-if="hasSubVariant"
      class="mt-50"
    >
      <!-- sub variant select -->
      <b-col
        md="4"
        xl="3"
      >
        <b-dropdown
          variant="outline-dark"
          block
          toggle-class="pl-1 border-default d-flex justify-content-between"
        >
          <!-- Content -->
          <template #button-content>
            <span
              v-if="subVariantLabel"
              class="text-capitalize"
            >{{ subVariantLabel }}</span>
            <span
              v-else
              class="text-muted"
            >Pilih..</span>
          </template>

          <b-dropdown-item
            v-for="(item, index) in options"
            :key="`variant-option-${index}`"
            :disabled="item === variantLabel"
            class="text-capitalize"
            @click="changeSubVariantLabel(item)"
          >{{ item }}</b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item>
            <div class="d-flex align-items-center w-100">
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              Tambah baru
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col
        v-if="subVariantLabel"
        md="8"
        xl="6"
      >
        <b-form-row>
          <b-col>
            <b-input-group class="input-group-merge position-relative">
              <b-form-input
                v-model="subVariantInput"
                :placeholder="`Ketik untuk menambahkan ${subVariantLabel || 'Variant'}`"
                @keypress.enter.prevent="addSubVariant"
              />
              <b-input-group-append
                class="cursor-pointer"
                is-text
                @click="addSubVariant"
              >
                <small class="mr-25">Enter</small><feather-icon icon="CornerDownLeftIcon" />
              </b-input-group-append>
            </b-input-group>
          </b-col>

          <b-col cols="auto">
            <button
              v-b-tooltip.v-dark.top="'Hapus Varian'"
              class="btn btn-icon btn-flat-dark"
              @click="deleteVariantSubVariant"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
              />
            </button>
          </b-col>
        </b-form-row>

        <div
          v-if="subVariants.length"
          class="d-flex gap-1 flexwrap-wrap mt-50"
        >
          <b-badge
            v-for="(subVariant, index) in subVariants"
            :key="`sub-variant-${index}`"
            class="whitespace-nowrap s-14 d-flex align-items-center"
            variant="primary"
          >
            {{ subVariant.name }}
            <feather-icon
              class="cursor-pointer ml-25"
              icon="XIcon"
              @click="removeSubVariant(index)"
            />
          </b-badge>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBadge,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BDropdownDivider, BFormRow,
} from 'bootstrap-vue'
import FormLabel from '@/layouts/components/FormLabel.vue'
// import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BFormRow,
    BRow,
    BCol,
    BButton,
    BBadge,
    BFormInput,
    FormLabel,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    // ValidationProvider,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    variant: {
      type: Object,
      default: () => {},
    },
    skus: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      variantInput: '',
      subVariantInput: '',
      hasSubVariant: false,
      // variantLabel: null,
      // subVariantLabel: null,
      // variants: [],
      // subVariants: [],
      // skus: [],
      options: ['ukuran', 'warna', 'laminasi'],
    }
  },
  computed: {
    hasVariant() {
      return this.variant
    },
    variants() {
      if (!this.variant) {
        return []
      }

      return this.variant.options
    },
    variantLabel() {
      if (!this.variant) {
        return null
      }

      return this.variant.name
    },
    subVariants() {
      if (!this.value) {
        return []
      }

      return this.value.options
    },
    subVariantLabel() {
      if (!this.value) {
        return null
      }

      return this.value.name
    },
  },
  watch: {
    value(value, oldValue) {
      // ignore if not first value bind. value bind is only on update mode
      if (oldValue) {
        return
      }

      // if
      if (this.subVariants.length) {
        this.hasSubVariant = true
      }

      // if variant label from database is not in the default option list
      if (this.subVariantLabel && this.options.indexOf(this.subVariantLabel) === -1) {
        // put in the list
        this.options.push(this.subVariantLabel)
      }
    },
  },
  methods: {
    changeSubVariantLabel(value) {
      const oldValue = this.value ? this.value.name : null

      this.change({
        ...this.value,
        name: value,
      })

      // The admin just select the sub-variant-label
      // reset the skus
      if (!oldValue && value) {
        this.$emit('skus', [])
      }
    },
    change(value) {
      this.$emit('input', value)
    },
    enableSubVariant() {
      this.hasSubVariant = true
      this.change({
        name: null,
        options: [],
      })
    },
    addSku(subVariantName, subVariantIndex) {
      const skus = [...this.skus]
      const sku = {
        price: null,
        compare_price: null,
        weight: null,
        stock: null,
        sku_number: null,
        min_purchase: 1,
        max_purchase: null,
        amount_multiple: 1,
        sub_variant: {
          name: subVariantName,
          index: subVariantIndex,
        },
        _is_added: true,
      }

      // loop all the sub-variants to pair with current variant
      this.variants.forEach((item, index) => {
        // put in the last index of each variant name
        skus.splice(((index + 1) * this.subVariants.length) - 1, 0, {
          ...sku,
          variant: {
            name: item.name,
            index,
          },
        })
      })

      this.$emit('skus', skus)
    },
    addSubVariant() {
      if (!this.subVariantInput) {
        return
      }

      // do not push if already exists
      if (this.subVariants.findIndex(item => item.name === this.subVariantInput) !== -1) {
        this.subVariantInput = ''
        return
      }

      // push to sub-variants with id null, means this sub-variant is not in database yet
      this.subVariants.push({ id: null, name: this.subVariantInput })

      this.addSku(this.subVariantInput, this.subVariants.length - 1)

      this.subVariantInput = ''
    },
    removeSubVariant(index) {
      const subVariant = this.subVariants[index]
      this.subVariants.splice(index, 1)

      const skus = this.skus
        // ignore removing sub variant when it is just added recently (not stored on database yet)
        .filter(item => !(item._is_added && item.sub_variant && item.sub_variant.name === subVariant.name))
        .map(item => {
          // put _is_deleted tag if the deleted sub variant is not recently added (has stored on database)
          // because we want to delete it from database
          if (item.sub_variant && item.sub_variant.name === subVariant.name) {
            return {
              id: item.id,
              _is_deleted: true,
            }
          }

          return item
        })

      this.$emit('skus', skus)
    },

    deleteVariantSubVariant() {
      // const subVariants = [...this.subVariants]
      //
      // for (let index = 0; index < subVariants.length; index += 1) {
      //   console.log(index)
      //   setTimeout(() => this.removeSubVariant(index), 1000)
      // }

      // while (this.subVariants.length) {
      //   this.removeSubVariant(0)
      // setTimeout(() => {
      //   this.removeSubVariant(0)
      // }, 1000)
      // setTimeout(() => {
      //   this.removeSubVariant(0)
      // }, 2000)
      // }

      this.hasSubVariant = false

      // const skus = this.skus
      //   .map(item => ({
      //     ...item,
      //     sub_variant: null,
      //   }))

      const skus = this.skus
        // ignore removing sub variant when it is just added recently (not stored on database yet)
        .filter(item => !(item._is_added))
        .map(item => {
          // put _is_deleted tag if the deleted sub variant is not recently added (has stored on database)
          // because we want to delete it from database
          if (item.sub_variant) {
            return {
              id: item.id,
              _is_deleted: true,
            }
          }

          return item
        })

      console.log('Ini sku nya', skus)

      this.change(null)
      this.$emit('skus', skus)
      // this.$nextTick(() => this.$emit('skus', skus))

      // this.hasSubVariant = false
      //
      // this.subVariantLabel = null
      //
      // this.variants = []
      // this.subVariants = []
      //
      // this.variantInput = ''
      // this.subVariantInput = ''
      //
      // this.skus = []
    },
  },
}
</script>

<style scoped>

</style>
